import { intersectionObserve } from "./_intersection-observer";
import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

const elements = document.querySelectorAll('section.slideshow');

function initImageSlider(id) {
    let slide = new Splide('#' + id, {
      type: 'loop',
      perPage: 4,
      gap: '36px',
      perMove: 1,
      drag: 'free',
      autoScroll: {
        speed: 0.5,
        autoStart: false,
      },
      arrows: false,
      pagination: false,
      breakpoints: {
        2268: {
          perPage: 4
        },
        1440: {
          perPage: 3.5,
          gap: '24px',
        },
        1024: {
          perPage: 2,
          gap: '16px',
        },
      }
    });
    slide.mount({ AutoScroll });

    intersectionObserve(elements, 0, 0.5, (element, intersectionRatio) => {
        (function(el) {
            if (intersectionRatio > 0 && !document.querySelector('#' + id).classList.contains('started')) {
              slide.destroy();
              slide.options = { autoScroll: {speed: 0.5, autoStart: true} };

              slide.mount({ AutoScroll });

              document.querySelector('#' + id).classList.add('started');
        } 
    })(element); 
  })
};

window.initImageSlider = initImageSlider;