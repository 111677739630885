const breakpoint = 1024;
const header = document.querySelector('header');

if(header) {
    let headerHeight = header.offsetHeight;
    let lastScrollTop = 0;
    const button = document.querySelector(".menu-button");
    const navBar = document.querySelector("header .menu-content")
    const body = document.querySelector("body");
    const parent = document.querySelectorAll(".menu-item-has-children >a")
    const arrow = document.querySelectorAll(".menu-item-has-children >svg")

    // Menu on scroll
    $(window).scroll(function (e) {
        let scrollTop = $(this).scrollTop();
        if ($(window).width() >= breakpoint && scrollTop <= 2) {
            header.classList.remove('sticky', 'show', 'animate');
        } else if (scrollTop === 0) {
            header.classList.remove('sticky', 'show', 'animate');
        } else if (scrollTop > headerHeight) {
            if (scrollTop < lastScrollTop) { // Scrolling up
                header.classList.add('sticky', 'animate', 'show')
            } else {
                header.classList.add('sticky');
                header.classList.remove('show');
            }
        }
        lastScrollTop = scrollTop;
    });

    // Close menu when clicking outside of it
    document.addEventListener('click', event => {
        const isClickInside = navBar.contains(event.target) || header.querySelector('.wrapper').contains(event.target);
        if (!isClickInside && navBar.classList.contains('show')) {
          closeMenu();
        }
    });

    function closeMenu() {
        navBar.classList.remove("anim");
        setTimeout(function () {
            navBar.classList.remove("show");
        }, 300);
        header.querySelector(".wrapper").classList.remove('anim');
        button.classList.remove("menu-close");
        header.classList.remove('show-menu');
        body.classList.remove('no-scroll');
        button.querySelectorAll('span')[0].innerHTML = 'Menu';
    }

    button.onclick = function toggleMenu() {
        if (navBar.matches('.show')) {
            closeMenu();
        } else {
            navBar.classList.add("show");
            navBar.classList.add("anim");
            button.classList.add("menu-close");
            header.classList.add('show-menu');
            button.querySelectorAll('span')[0].innerHTML = 'Close';
            setTimeout(function () {
                header.querySelector(".wrapper").classList.add('anim');
            }, 200);
            body.classList.add('no-scroll');

            // Hides dropdown when menu is hidden
            parent.forEach(function (ele) {
                ele.classList.remove("show-ul");
            });
        }
    };

    //dropdown
    /**
    * check if parent has child with classname
    * on click add or remove class
    */
    parent.forEach(function (ele) {
        ele.addEventListener('click', function (e) {
            e.preventDefault();
                if (ele.parentNode.classList.contains('show-ul')) {
                window.location = e.target.href;
            } else {
                ele.parentNode.classList.add("show-ul");
            }
        });
    });
    arrow.forEach(function (ele) {
        ele.addEventListener('click', function (e) {
            e.preventDefault();
            if (ele.parentNode.classList.contains('show-ul')) {
                ele.parentNode.classList.remove("show-ul");
            } else {
                ele.parentNode.classList.add("show-ul");
            }
        });
    });
}